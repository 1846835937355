.accounts-table-header {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 3rem;
}

.accounts-table-header-filter {
  width: 10rem;
  margin-top: 5rem;
}

.accounts-table-title {
  display: flex;
  align-items: center;
  flex: 1;
}

.accounts-table-expired-date {
  color: red !important;
}
