.addUserForm {
  background-color: white;
  padding: 3em 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  border-radius: 0.5rem;
  gap: 1rem;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;

  .input:not(:nth-last-of-type(1)) {
    margin-right: 1em;
  }
}

.addUserInput {
  width: 30rem;
}

.addUserForm--noPadding {
  padding: 0;
}
