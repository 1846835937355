.rootRegister {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.rootRegister__buttonBox {
  background-color: white;
  width: fit-content;
  padding: 5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
}

.login-btn {
  text-align: center;
  padding: 1rem 0px 1rem 0px;
  /* width: 20rem; */
}

.input {
  width: 20rem;
  padding: 1rem 0.25rem 1rem 0.25rem;
  font-size: 1.25rem;
}

.space-top {
  margin-top: 1rem !important;
}

.login-label-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.login-label {
  width: fit-content;
}

.forgot-password-link {
  text-decoration: none;
}