.documents-page-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
}

.documents-page-content {
  display: flex;
  flex: 2;
}
