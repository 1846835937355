.lock-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding: 2rem 2rem 0 2rem;
}

.lock-modal-select {
  width: 100%;
}

.lock-modal-select--new {
  margin-bottom: 1.2rem;
}

.lock-modal-button {
  width: 100%;
}

.css-1km1ehz.Mui-selected {
  background-color: #c8f5fe !important;
}